import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import purple from '@material-ui/core/colors/purple';
import Avatar from '@material-ui/core/Avatar';
import { unstable_Box as Box } from '@material-ui/core/Box';

const styles = {
    root: {
        flexGrow: 1,
      },
      box: {
        zIndex: 3,
        top: -30,
        position: "relative",
        margin: 0,
      },
      bigAvatar: {
        marginStart: 20,
        width: 60,
        height: 60,
        objectFit: "fill",
          },
      paper: {
        padding: 24,
        textAlign: 'center',
        color: purple['A200'],
      },
    card: {
        margin:10,
        zIndex: 1
        
    },
    bullet: {
        display: 'inline-block',
        margin: '0 ',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 16,
    },
    name: {
        fontSize: 14,
        fontWeight: "bold",
        marginRight: 25,
        marginLeft: 25,
    },
    subname: {
        fontSize: 12,
        marginRight: 25,
        marginLeft: 25,
    },
    quotation: {
        fontSize: 100,
        marginTop:20,
    },
    excerpt: {
      margin: 20,
  },
    pos: {
    },
};

const Testimonial = props => {
    const { classes, testimonials, theme } = props;
    return (
        <React.Fragment>
          
<div className={classes.root}>
      <Grid container spacing={10}>

      {testimonials.map(testimonial => {
            const {
              node,
              node: {
                excerpt,
                frontmatter: {cover, author, position}

              }
            } = testimonial;
            const { language, theme, item: { label, to, icon: Icon } = {}, } = testimonial;
            return (
                <Grid item xs={12} sm={4} spacing={8}>
        <Card elevation={16} 
        className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                <p className={classes.quotation}>
        “
      </p>
                <blockquote>
                <p className={classes.excerpt}>
                {excerpt}
                </p> 
                     </blockquote>   
                 </Typography>
            </CardContent>
        </Card>
        <Box className={classes.box}>
        <Avatar alt={author} title={author} src={cover.children[0].fluid.src} className={classes.bigAvatar} />
        <Typography className={classes.name} color="textSecondary" gutterBottom>
        {author}
                 </Typography>
                 <Typography className={classes.subname} color="textSecondary" gutterBottom>
                 {position}
                 </Typography>
        </Box>
           </Grid>
            );
          })}
      </Grid>
    </div>
           {/* --- STYLES --- */}
           <style jsx global>{`
.quotation-mark{
    
    margin-top: -10px;
    font-weight: bold;
    font-size:600px;
    color:white;
    font-family: "Times New Roman", Georgia, Serif;
    
}
.contactIcon{
  height: 300px;
  width: 300px;
}
  
  
      `}</style>
        </React.Fragment>
    );
}


export default withStyles(styles)(Testimonial);