'use strict';
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Hero from "../components/Hero";
import Seo from "../components/Seo";
import Testimonial from "../components/Testimonial";
import Service from "../components/Service";
import {FormattedMessage} from 'react-intl';
import ContactIcon from '../../static/illustrations/title_contact.svg'
import Contact from "../components/Contact";

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        testimonials: { edges: testimonials = [] },
        services: { edges: services = [] },

        bgDesktop: {
          resize: { src: desktop }
        },
        bgTablet: {
          resize: { src: tablet }
        },
        bgMobile: {
          resize: { src: mobile }
        },
        site: {
          siteMetadata: { facebook }
        }
      },
      language
    } = this.props;
    const backgrounds = {
      desktop,
      tablet,
      mobile
    };

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Hero scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme} />
          )}
        </ThemeContext.Consumer> 

        <hr ref={this.separator} />
         <p id="testimonials" className="mtext"><FormattedMessage
                    id="testimonials" 
                    /> 
    </p> 

        <ThemeContext.Consumer>
          {theme => 
         <Testimonial testimonials ={testimonials.filter(item => {

           return item.node.frontmatter.language == "en";

         })}/>
          }
        </ThemeContext.Consumer>

         <ThemeContext.Consumer>
          {theme => (
            <Contact ContactIcon= {ContactIcon} className="contact" theme={theme} />
          )}
        </ThemeContext.Consumer> 
        <Seo facebook={facebook} language={language}/>

        <style jsx>{`

          .mtext{
            text-align: center;
            font-size: 30px;
            margin: 2em 0 2em 0;
          }

        `}</style>
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            author
            position
            language
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//testimonials/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 800)
          frontmatter {
            title
            category
            author
            position
            language
            cover {
              children {
                ... on ImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//services/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 800)
          frontmatter {
            title
            category
            author
            position
            language
            link
            cover {
              children {
                ... on ImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;

//hero-background
