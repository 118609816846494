import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import purple from '@material-ui/core/colors/purple';
import Avatar from '@material-ui/core/Avatar';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import { Link } from "gatsby";
import LazyLoad from 'react-lazyload';

const styles = {
    root: {
        flexGrow: 1,
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: '#fbfbff',
      },
      box: {
        zIndex: 3,
        top: -30,
        position: "relative",
      },
      bigAvatar: {
        width: 200,
        height: 350,
        objectFit: "cover",
          },
      paper: {
        padding: 24,
        textAlign: 'center',
        color: purple['A200'],
      },
    card: {
        minWidth: 275,
        margin:10,
        zIndex: 1
        
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 16,
    },
    control: {
      padding: 16
      },
    name: {
        fontSize: 20,
        fontWeight: "bold",
        marginTop: 8,
        marginBottom: 8,
    },
    subname: {
        fontSize: 14,
        fontWeight: "bold",
        textDecoration: 'underline',
    },
    quotation: {
        fontSize: 100,
        marginTop:20,
    },
    pos: {
    },
};

const Service = props => {
    const { classes, testimonials, theme } = props;
    return (
        <React.Fragment>
          
<div className={classes.root}>
<p className="mtext"><FormattedMessage
                    id="services" 
                    /> 
    </p> 

      <Grid container spacing={4} className={classes.control}>

      {testimonials.map(testimonial => {
            const {
              node,
              node: {
                excerpt,
                frontmatter: {cover, link, language, author, position}

              }
            } = testimonial;
            const { theme, item: { label, to, icon: Icon } = {}, } = testimonial;
            return (
                <Grid item xs={12} sm={4}>
      <LazyLoad height={350}>
                <img alt={author} title={author} src={cover.children[0].fluid.src} className={classes.bigAvatar} />
                </LazyLoad>
                <Typography className={classes.name} color="textSecondary" gutterBottom>
                 {author}
                 </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                <blockquote>
                <p>
                {excerpt}
                </p> 
                     </blockquote>   
                 </Typography>
                 
                 <Link to={'/'+language+'/'+link} key={link}>

                 <Typography className={classes.subname} color="textSecondary" gutterBottom>
              
                 <FormattedMessage
                    id="readMore"/>
                                     </Typography>
                                     </Link>

           </Grid>
            );
          })}
      </Grid>
    </div>
           {/* --- STYLES --- */}
           <style jsx global>{`
.quotation-mark{
    
    margin-top: -10px;
    font-weight: bold;
    font-size:600px;
    color:white;
    font-family: "Times New Roman", Georgia, Serif;
    
}
.contactIcon{
  height: 300px;
  width: 300px;
}
.mtext{
  text-align: center;
  font-size: 30px;
  margin: 2em 0 2em 0;
}
  
      `}</style>
        </React.Fragment>
    );
}


export default withStyles(styles)(Service);